@import './component.css';

* {margin:0; padding:0; box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; font-weight:100; font-family: 'Roboto', 'Nanum Gothic', ' sans-serif';}
html, body, #root {height:100%;}
body {font-size:13px; font-weight:100; line-height:1.5;}
p, h1, h2, h3, h4, h5, span {font-weight:100;}

/** Layout */
.hlLayout {
  width: 100%; 
  height: 100%;
}
.hlLayout.hlLayout-mobile {
  min-width: 500px;
}
.container-contents {
  padding: 0 20%;
}

/** Page */
.page {
  width: 100%;
}
.page-home {
  height: calc(100% - 90px);
  margin-top: 90px; 
  background-image: url("./../images/home.png");
}
.page:not(.page-home):not(.page-work):not(.page-message) {
  padding-left: calc((100% - 1200px) / 2);
  padding-right: calc((100% - 1200px) / 2);
}
.container:not(.container-work) {
  width: 1200px;
  height: 100%;
}

/** Page About */

.image-wrap {
  width:100%;
}
.image-wrap > img {
  width:100%;
  height:auto;
}
/** Navbar */
.navbar {
  width: 100%;
  height: 90px;
  padding: 0 70px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index:999;
}
.navbar-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navbar-logo {
  font-size: 35px;
  justify-self: start;
}
.navbar-logo > a {
  line-height: 90px;
  padding: 0 15px;
}
.navbar-logo > a > img {
  vertical-align: middle;
}
.navbar-menu {
  list-style: none;
  display: flex;
  justify-content: end;
}
.navbar-links {
  font-size: 12px;
  line-height: 90px;
  color: #6b6b6b;
  background: #fff;
  padding: 0 10px;
  text-decoration: none;
}
.navbar-links:hover {
  color: #9d9d9d;
}

.navbar-menu-icon {
  display:none;
}

.view-line {padding:20px 0; text-align:center;}
.view-line > div {padding-left:0; padding-right:0;}
.view-line hr {width:40%; border-top:1px solid #e1e1e1; margin:1px 0; display:inline-block;}
.view-line .diamond-box {margin:8px; display:inline-block; line-height:11px; vertical-align:middle;}
.view-line .diamond-box > .diamond-line {content:" "; min-width:6px; min-height:6px; display:inline-block; border:1px solid #e1e1e1; -webkit-transform:rotate(45deg); -moz-transform:rotate(45deg); -ms-transform:rotate(45deg); -o-transform:rotate(45deg); transform:rotate(45deg); border-color:#999399; margin:0 2px;}

/** Page HOME */
.page-home .text-box {width:50%; position:absolute; top:50%; right:0; color:#fff;}

.page-home .text-box > h2 {font-size:60px;}
.page-home .text-box > h3 {font-size:32px;}
.page-home .text-box > span {font-size:20px;}
.page-home .text-box > p {font-size:13px;}

/** Page ABOUT */
.header {width:100%; padding:90px 0px 20px;}
.header.about > div {width:80%; padding-right:15px; padding-left:15px; margin:0 auto;}
.header-contents.about {text-align:center;}
.header-contents.about > h5 {font-size:16px; font-weight:100; line-height:1.8; margin-top:0; margin-bottom:15px;}
.header-contents.about > ul {padding:0; list-style:none;}
.header-contents.about > ul > li:first-child > span {content:''; display:inline-block; border-top:9px solid #505050; border-right:6px solid transparent! important; border-bottom:none! important; border-left:6px solid transparent! important;}
.header-contents.about > ul > li:last-child > span {content:''; display:inline-block; width:1px; height:42px; border-left:1px solid #9a9a9a; margin:0 auto;}

.contents-about-history {
  padding: 80px 0;
  text-align: center;
}
.contents-about-history i {
  content:''; 
  display:inline-block; 
  border-color:#000; 
  width:4px; 
  height:1px; 
  border-top:2px solid #dddddd; 
  margin:0; 
  position:absolute; 
  top:50%;
}
.contents-about-history h6 {
  font-size:14px; 
  display:inline-block; 
  color:#000; 
  margin-top:0; 
  margin-bottom:0;
}
.contents-about-history .head-title {
  margin-top:20px; 
  margin-bottom:30px;
}
.contents-about-history .head-title > span {
  font-size:25px;
}
.workHistory {
  padding-top: 30px;
}
.workHistory-year {
  font-size: 1.3rem;
  color: #dddddd;
}

/** Page WORK */
.container-work {
  width: 100%;
}
.viewContents-work {
  background: #f7f7f7;
}
.contents-work {
  height: 600px;
}
.contents-work .image-wrap {
  height: 100%;
  background-size: cover !important; 
  background-repeat: no-repeat !important; 
  background-position: center center !important;
}
.contents-work .text-box {padding:0 10px; margin:auto 0;}
.contents-work .text-box.odd {text-align:right}
.contents-work .text-box h3 {margin:0; color:#333333;font-size:35px;}
.contents-work .text-box h4 {margin:0; color:#333333;font-size:25px;}
.contents-work .text-box h4 > span:last-child {font-size:20px !important;}
.contents-work .text-box p {font-size:13px; color:#666666; margin-top:20px; margin-bottom:30px;}

/** Page PARTNER */
.viewContents-partner {padding:20px 0;}
.viewContents-partner .partner-grid {margin-right:15px; margin-left:15px; display:grid; grid-template-columns:repeat(3, 1fr); gap:30px;}
.viewContents-partner .partner-grid .img-wrap > img {width:100%; height:auto;}
.viewContents-partner .partner-grid .text-box {margin:0; padding-top:10px; line-height:1.5;}
.viewContents-partner .partner-grid .text-box > p {margin:0; text-align:center; font-size:12px;}

/** Page CONTACT */
.viewContents-contact {padding:20px 0;}
.viewContents-contact .text-box {width:40%; padding-top:100px;}
.viewContents-contact .text-box > div {padding:0 15px;}
.viewContents-contact .text-box > div:not(:first-child) {margin-top:50px;}
.viewContents-contact .text-box h3 {margin-top:0; font-size:24px !important; font-style:italic;}
.viewContents-contact .text-box p {margin-top:0; color:#333333; font-size:14px !important;}
.viewContents-contact .map-wrap {width:55%; height: 650px; padding-right:15px; padding-left:15px; background-image:url('./../images/map.png'); background-size:cover; background-repeat:no-repeat; background-position:center center}

/* Page MESSAGE */
.page-message {
  background-image: url("./../images/message.png");
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.page-message * {
  color: #fff;
}
.page-message .header-contents.about > ul > li:first-child > span {
  border-top:9px solid #fff;
}
.page-message .header-contents.about > ul > li:last-child > span {
  border-left:1px solid #fff;
}
.viewContents-message > div {
  width: 45%;
  margin-bottom: 40px;
  text-align: center;
}
.viewContents-message .text-box > p{
  margin-bottom: 20px;
}
.form-message {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.form-message > div {
  text-align: left;
  margin-bottom: 14px;
}
.form-inputs {
  border: 1px solid #fff;
  background: transparent;
  padding: 0 10px;
  margin-bottom: 14px;
  font-size: 12px;
  line-height: 33px;
}
.form-inputs::placeholder {
  font-size: 12px;
  color: #fff;
}
.form-inputs:focus {
  outline: none;
}
.form-inputs[type='checkbox'] {
  margin-right: 10px;
}
.form-inputs-message {
  height: 130px;
  resize: none;
}
.form-privacy-agree {
  display: inline;
  vertical-align: 2px;
}
.form-privacy-agree > span {
  text-decoration: underline;
}
.form-privacy-agree > span:hover {
  cursor: pointer;
}
.form-privacy-agree-content {
  resize: none;
}
.form-button:hover {
  cursor: pointer;
  background: #fff;
  color: #000;
  transition: all .5s ease;
}

/** Footer */
.footer {
  padding:20px 0;
}
.footer > div {
  width:65%; 
  padding-right:15px; 
  padding-left:15px; 
  margin:0 auto;
}
.footer .row > div {
  padding:0 15px; 
  text-align:center;
}
.footer .row hr {
  width:35px; 
  border:none; 
  border-top:2px solid #d6d7d8; 
  margin:0 auto;
}
.footer .row .text-box {
  margin-top:0; 
  font-size:12px !important;
}
.footer .footer-company-info {
  padding-top:50px !important;
}
.footer .footer-company-info > span {
  color:#979797; 
  font-size:11px; 
  line-height:1.6; 
  letter-spacing:0.03em; 
  margin-bottom:0;
}

.footer-line {
  padding:20px 0;
}
.footer-line > div {
  width:65%; 
  padding-right:15px; 
  padding-left:15px; 
  margin:0 auto;
}
.footer-line .row {
  padding:0 15px; 
  text-align:center;
}
.footer-line .row span {
  width:1px; 
  height:87px; 
  content:" "; 
  display:inline-block; 
  margin:15px auto; 
  border-left:1px solid #888888; 
  border-color:#cccccc;
}

/* Desktop - max-width: 1200px */
@media screen and (min-width: 769px) and (max-width: 1200px) {
  html {
    font-size: 12px;
  }
  .page:not(.page-home):not(.page-work):not(.page-message) {
    padding-left: calc((100% - 1000px) / 2);
    padding-right: calc((100% - 1000px) / 2);
  }
  .navbar {
    padding: 0 20px;
  }
  .container:not(.container-work) {
    width: 100%;
  }
}

/* Mobile */
@media screen and (max-width: 768px) {
  .page {
    padding: 0;
  }
  .container {
    width: 100% !important;
  }
  /* Navbar */
  .navbar {
    height: 50px;
    padding: 0 10px;
    /* position: relative; */
  }
  .navbar-logo > a {
    line-height: 50px;
  } 
  .navbar-menu {
    display: flex;
    flex-direction: column;
    text-align: center;
    width:100%;
    position: absolute;
    top: 50px;
    left: -100%;
    opacity: 1;
  }
  .navbar-menu.active {
    background: #333;
    left:0;
    opacity: 1;
    /* transition: all 1.0s ease; */
    z-index: 1;
  }
  .navbar-links {
    padding: 10px;
    width:100%;
    display:table;
  }
  .navbar-menu-icon {
    height: 50px;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 20%);
  }
  
  /* Page Home */
  .page-home {
    height: calc(100% - 50px);
    margin-top: 50px;
  }
  .page-home .text-box {
    width: 100%;
    padding: 0 20px;
    top: 30%;
  }

  /* Page Partner*/
  .viewContents-partner .partner-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  /* Page Contact */
  .viewContents-contact .text-box {
    width: 100%;
    padding-top: 0;
  }
  .viewContents-contact .map-wrap {
    width: 100%;
    min-height: 300px;
    margin-top: 30px;
  }
}